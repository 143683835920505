function detectMobile(useragent) {
  if (useragent.match(/^(?=.*Android)(?=.*Mobile).*/)) {
    return true;
  }
  if (useragent.match(/.*iphone.*/i)) {
    return true;
  }
  return false;
}

export const showMobileLink = () => {
  return (
    typeof window !== "undefined" && !detectMobile(window.navigator.userAgent)
  );
};

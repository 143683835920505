import React from "react";

export default function Quote({
  person = "",
  quote = "",
  strokeColor = "#101133",
  textColor = "text-copy-secondary",
  y = 4581,
  x = 20.001,
}) {
  return (
    <div className="relative quote-container w-full">
      <div className="text-left md:hidden">
        <blockquote
          className={`mt-6 md:mt-0 ${textColor} text-base font-normal md:text-2xl`}
          id="quote-small"
        >
          {quote}
          <cite className="block mt-4 lg:mt-8 not-italic font-semibold md:text-2xl">
            {person}
          </cite>
        </blockquote>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1035.063 481.505"
        className="w-full h-auto"
      >
        <defs>
          <clipPath id="clip-path">
            <rect width="807.541" height="481.506" fill="none" />
          </clipPath>
          <clipPath id="clip-path-2">
            <rect
              width="187.549"
              height="150.35"
              fill="none"
              stroke="#707070"
              strokeWidth="3"
            />
          </clipPath>
        </defs>
        <g transform="translate(91.514 -4483.317)">
          <foreignObject
            x={x}
            y={y}
            width="65%"
            height="100%"
            className="invisible md:visible"
          >
            <div xmlns="http://www.w3.org/1999/xhtml">
              <blockquote
                className={`mt-6 md:mt-0 text-base font-normal md:text-2xl ${textColor}`}
              >
                {quote}
                <cite className="block mt-4 lg:mt-8 not-italic font-semibold md:text-2xl">
                  {person}
                </cite>
              </blockquote>
            </div>
          </foreignObject>
          <g
            transform="translate(-91.514 4483.317)"
            className="invisible md:visible"
          >
            <g clipPath="url(#clip-path)">
              <path
                d="M807.027,2.076,198.089,1.5C89.595,1.421,1.579,89.307,1.5,197.8v.142c0,108.494,87.95,196.445,196.444,196.447h312.09l87.55,81.961V394.39H807.02"
                transform="translate(0.513 0.513)"
                fill="none"
                stroke={strokeColor}
                strokeWidth="3"
              />
            </g>
          </g>
          <g transform="translate(943.549 4633.667) rotate(180)">
            <g clipPath="url(#clip-path-2)">
              <path
                d="M58.763,57.3c0,13.5,28.086,15.683,28.086,48.873,0,24.438-17.5,42.674-41.944,42.674-26.264,0-43.4-23.344-43.4-48.144C1.5,58.033,41.622,1.5,66.421,1.5c6.2,0,17.508,4.378,17.508,12.036S58.763,34.325,58.763,57.3m99.205,0c0,13.5,28.081,15.683,28.081,48.873,0,24.438-17.5,42.674-41.94,42.674-26.26,0-43.4-23.344-43.4-48.143C100.7,58.033,140.825,1.5,165.626,1.5c6.2,0,17.5,4.378,17.5,12.036S157.967,34.325,157.967,57.3"
                fill="none"
                stroke={strokeColor}
                strokeMiterlimit="10"
                strokeWidth="3"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

import React from "react";
import PropTypes from "prop-types";

export default function Triangle(props) {
  const {
    image = {},
    css = {},
    num = "zero",
    children,
    hasCursor,
    selected,
    rotate = 20,
    translateX = 173.769,
    translateY = 0.001,
    ...rest
  } = props;
  const { path = "" } = css;
  return (
    <div id={`shape-container-${num}`} {...rest}>
      <svg
        className={`shape-container-svg`}
        viewBox="0 0 683.129 662.819"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g>
          {image && image.src ? (
            <clipPath id="shape-top">
              <path
                id="shape-top"
                d="M396.766,72.621C369.163,24.789,321.092.586,272.576.01,223.283-.576,173.534,23.627,145.26,72.621L19.638,290.305C-5.441,333.767-5.178,382.1,12.942,422.39c22.016,48.951,70.873,85.677,132.45,85.677H396.635c58,0,104.716-32.584,128.483-77.566,21.919-41.486,24.138-93.637-2.73-140.193Z"
                transform={`translate(${translateX} ${translateY}) rotate(${rotate})`}
              />
            </clipPath>
          ) : (
            <path
              id="shape-top"
              d="M396.766,72.621C369.163,24.789,321.092.586,272.576.01,223.283-.576,173.534,23.627,145.26,72.621L19.638,290.305C-5.441,333.767-5.178,382.1,12.942,422.39c22.016,48.951,70.873,85.677,132.45,85.677H396.635c58,0,104.716-32.584,128.483-77.566,21.919-41.486,24.138-93.637-2.73-140.193Z"
              transform={`translate(${translateX} ${translateY}) rotate(${rotate})`}
              fill={`${path}`}
            />
          )}
        </g>
        {image && image.src && (
          <image
            className={`clip-path-image ${
              hasCursor
                ? `pointer-events-none lg:cursor-pointer lg:pointer-events-auto`
                : ``
            }`}
            clipPath="url(#shape-top)"
            xlinkHref={image.src}
            preserveAspectRatio="xMidYMin slice"
          ></image>
        )}
        {image && image.src && selected && (
          <>
            <path
              d="M44 24.5C51.899 20.529 60.5 9 60.5 9 49.908 2.697 43.291.695 30 .5c-12.44.332-19.035 2.195-30 9 0 0 9.356 12.294 18 16 4.614 1.978 7.48 3.07 12.5 3 5.498-.077 8.587-1.53 13.5-4z"
              style={{
                transform: `translate3d(110px, 251px, 0)`,
                fill: `${selected ? `#ea255c` : `none`}`,
              }}
            />
          </>
        )}
      </svg>
      {children}
    </div>
  );
}

Triangle.propTypes = {
  image: PropTypes.object,
  css: PropTypes.object,
  num: PropTypes.string.isRequired,
  hasCursor: PropTypes.bool,
  selected: PropTypes.bool,
  children: PropTypes.node,
};

import React from "react";
import { Fade } from "react-awesome-reveal";

import { showMobileLink } from "../../utils/helpers";
import MLink from "../common/link";
import useImage from "../../hooks/use-image";
import Square from "../common/triangle";
import NQuote from "../common/newQuote";

const link = showMobileLink() ? (
  <MLink
    to={`/careers#open-positions`}
    className="bg-ci-blue-400 text-white font-medium px-8 py-3 mt-8 cursor-pointer transition rounded-full hover:bg-ci-blue-600 duration-300 ease-out"
  >
    Apply Today
  </MLink>
) : (
  <a
    href="//jobs.jobvite.com/clarityinnovations/?nl=1&fr=false"
    className="bg-ci-blue-400 text-white font-medium px-8 py-3 mt-8 cursor-pointer transition rounded-full hover:bg-ci-blue-600 duration-300 ease-out"
    rel="noopenner noreferrer"
    target="_blank"
  >
    Apply Today
  </a>
);

const TestimonialsSection = () => {
  const { testimonial: testImg, best2020, best2021, designAward } = useImage();
  return (
    <section className="bg-ci-light-gray p-4 mt-48 md:p-6 lg:px-16 h-full w-full relative z-10 grid gap-6 lg:gap-0 lg:grid-cols-2 lg:grid-rows-2">
      <div
        className="col-auto self-center md:self-end mt-40 max-w-xs lg:col-auto text-copy-secondary w-full text-left relative md:max-w-lg lg:col-start-2 lg:self-center xl:max-w-full xl:mt-24 2xl:mt-16"
        style={{ justifySelf: "end" }}
      >
        <Fade direction="right" triggerOnce>
          <h2 className="text-3xl lg:text-4xl font-semibold text-left xl:text-5xl leading-snug">
            Careers with Clarity
          </h2>
          <p className="lg:max-w-xs text-base leading-relaxed mt-5 mb-8 lg:mt-4 lg:mb-8 text-left md:text-lg xl:max-w-lg">
            Our employees have voted us a Top Workplace for the second year in a
            row. If you are interested in joining Clarity&lsquos diverse, work
            hard/play hard team, check out open positions or contact our
            recruiting team!
          </p>
          {link}
        </Fade>
      </div>
      <div className="flex gap-2 mx-auto my-8 lg:hidden">
        <img
          src={best2020.sharp.fluid.src}
          alt="Top Work Places 2020 Baltimore Sun Media"
          className="w-auto h-32"
        />
        <img
          src={best2021.sharp.fluid.src}
          alt="Top Work Places 2021 Baltimore Sun Media"
          className="w-auto h-32"
        />
        <img
          src={designAward.sharp.fluid.src}
          alt="A'Design Award Winner 2021"
          className="w-auto h-32"
        />
      </div>
      <div className="col-auto my-12 mx-auto lg:mt-24 lg:col-span-2 2xl:mt-16 lg:max-w-5xl relative w-full lg:flex">
        <NQuote
          person="Sarah Lee, UX/UI Designer"
          quote="Working for Clarity allows me to do work I love in support of a larger cause. I know my designs, and the software, have an impact on end users because I work with them to solve real world
          problems."
        />
        <div className="hidden gap-2 mx-auto my-8 lg:flex lg:items-end lg:pb-8">
          <img
            src={best2020.sharp.fluid.src}
            alt="Top Work Places 2020 Baltimore Sun Media"
            className="w-auto h-32 lg:h-28"
          />
          <img
            src={best2021.sharp.fluid.src}
            alt="Top Work Places 2021 Baltimore Sun Media"
            className="w-auto h-32 lg:h-28"
          />
          <img
            src={designAward.sharp.fluid.src}
            alt="A'Design Award Winner 2021"
            className="w-auto h-32 lg:h-28"
          />
        </div>
      </div>
      <Square
        num="one"
        image={{ src: testImg.sharp.fluid.src }}
        rotate={81}
        translateX={591.769}
        translateY={73.001}
      />
      <Square num="two" css={{ path: "var(--ci-blue-400)" }} />
    </section>
  );
};

export default TestimonialsSection;
